<template>
    <v-container fill-height class="text-center">
        <v-row align-content="center" justify="center" class="opacity-7">
            <v-col cols="12" class="text-center warning--text">
                <div v-if="msg" v-html="msg"></div>
                <div v-else>
                    <slot>
                        Something went wrong,
                        <br />
                        please try again later!
                    </slot>
                </div>
            </v-col>
            <v-col cols="6">
                <v-icon large color="warning">mdi-alert-rhombus-outline</v-icon>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'DashboardError',
    props: {
        msg: {
            type: String,
            default: ''
        }
    }
}
</script>
